import React, { useState } from 'react';
import Icon from '../Icon';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const HabitsAccordionTab = ({ infoText, tabText, tabClasses, infoClasses }) => {
  const [isTabOpen, setIsTabOpen] = useState(false);
  const clickHandler = () => {
    if (infoText) {
      setIsTabOpen((currentStatus) => !currentStatus);
    }
  };
  return (
    <>
      <div
        onClick={clickHandler}
        className={classNames('habitsAccordion__tab', tabClasses)}
      >
        <div>{tabText}</div>
        {infoText ? (
          isTabOpen ? (
            <Icon name="caratdown" />
          ) : (
            <Icon name="caratright" />
          )
        ) : null}
      </div>
      {infoText && (
        <div
          className={classNames(
            'habitsAccordion__infoContainer',
            isTabOpen || 'habitsAccordion__hidden',
            infoClasses
          )}
        >
          <p>{infoText}</p>
        </div>
      )}
    </>
  );
};

HabitsAccordionTab.propTypes = {
  /** Text displayed in the tab */
  infoText: PropTypes.string,
  /** Text displayed in the info section */
  tabText: PropTypes.string,
  /** Classes applied to the tab */
  tabClasses: PropTypes.array,
  /** Classes applied to the info section */
  infoClasses: PropTypes.array,
};

export default HabitsAccordionTab;
