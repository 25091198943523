import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Image from '../components/Image';
import HabitsWheel from '../components/HabitsWheel';
import HabitsAccordion from '../components/HabitsAccordion';
import Hero from '../components/Hero';
import Subfooter from '../components/Subfooter';
import '../styles/pages/_engineer.scss';

const Engineer = () => (
  <Layout title="Home">
    <Hero
      mediaSource="img-lead-think-engineer.jpg"
      textOverlay={{ heading: 'Think Like an Engineer' }}
    />
    <Section className="pt-3">
      <Container fullWidth>
        <Row>
          <Column size={6}>
            <Image filename="img-think-engineer-frame-mind.jpg" />
          </Column>
          <Column size={6} className="engineer__intro">
            <h2>Engineering is a Frame-of-Mind</h2>
            <p>
              Turning plans, ideas, and inspiration into concrete results
              requires problem-solving skils, collaboration, and determination.
              Show students how important their intentions are in realizing a
              better future with this essential teaching aid.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <Column size={12}>
            <HabitsAccordion />
            <HabitsWheel />
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pb-0">
      <Subfooter />
    </Section>
  </Layout>
);

export default Engineer;
