import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import textValues from './textValues.json';
import './styles.scss';
import * as d3 from 'd3';

const HabitsWheel = () => {
  const svgRef = useRef(null);
  const [textInfo, setTextInfo] = useState(textValues.learning);

  const navigateToEducators = () => navigate('/educators/video-topic-series');

  useEffect(() => {
    const svgEl = d3.select(svgRef.current);

    const mouseOver = (e) => {
      d3.select(`#${e.target.id}`).classed('habitsWheel__hoveredText', true);
      d3.select(`#${e.target.id}`).classed(
        'habitsWheel__nonHoveredText',
        false
      );
      d3.select(`#learning`).classed('habitsWheel__hoveredText', false);
      d3.select(`#learning`).classed('habitsWheel__nonHoveredText', true);
      setTextInfo(textValues[e.target.id]);
    };

    const mouseOut = (e) => {
      d3.select(`#${e.target.id}`).classed('habitsWheel__hoveredText', false);
      d3.select(`#${e.target.id}`).classed('habitsWheel__nonHoveredText', true);
      d3.select(`#learning`).classed('habitsWheel__hoveredText', true);
      d3.select(`#learning`).classed('habitsWheel__nonHoveredText', false);
      setTextInfo(textValues.learning);
    };

    const groupedMouseOver = (e) => {
      d3.select(`#${e.target.parentElement.id}`).attr(
        'class',
        'habitsWheel__hoveredText'
      );
      d3.select(`#learning`).classed('habitsWheel__hoveredText', false);
      d3.select(`#learning`).classed('habitsWheel__nonHoveredText', true);
      setTextInfo(textValues[e.target.id]);
    };

    const groupedMouseOut = (e) => {
      d3.select(`#${e.target.parentElement.id}`).attr(
        'class',
        'habitsWheel__nonHoveredText'
      );
      d3.select(`#learning`).classed('habitsWheel__hoveredText', true);
      d3.select(`#learning`).classed('habitsWheel__nonHoveredText', false);
      setTextInfo(textValues.learning);
    };

    const circleX = 67;
    const circleY = 50;
    const outerCircleRadius = 33;
    const middleCircleRadius = 24;
    const innerCircleRadius = 14;

    // clear svg content before adding new elements
    svgEl.selectAll('*').remove();

    svgEl
      .append('rect')
      .attr('x', 0)
      .attr('y', 13)
      .attr('width', 41)
      .attr('height', 33)
      .attr('class', 'habitsWheel__infoSVG');

    svgEl
      .append('line')
      .attr('x1', 3)
      .attr('y1', 17)
      .attr('x2', 13)
      .attr('y2', 17)
      .attr('class', 'habitsWheel__infoUpperBar');

    // Large outer dark blue circle
    svgEl
      .append('circle')
      .attr('cx', `${circleX}%`)
      .attr('cy', `${circleY}%`)
      .attr('r', outerCircleRadius)
      .attr('class', 'habitsWheel__outerRing');

    // Middle blue circle
    svgEl
      .append('circle')
      .attr('cx', `${circleX}%`)
      .attr('cy', `${circleY}%`)
      .attr('r', middleCircleRadius)
      .attr('class', 'habitsWheel__middleRing');

    // Inner light blue circle
    svgEl
      .append('circle')
      .attr('cx', `${circleX}%`)
      .attr('cy', `${circleY}%`)
      .attr('r', innerCircleRadius)
      .attr('class', 'habitsWheel__centerRing');

    // Header Content
    const headerPath = d3.path();
    headerPath.arc(circleX, outerCircleRadius, 29, 3.14, 0);

    svgEl
      .append('path')
      .style('fill', 'none')
      .attr('d', headerPath)
      .attr('id', 'headerPath');

    svgEl
      .append('text')
      .append('textPath')
      .attr('startOffset', '50%')
      .attr('text-anchor', 'middle')
      .attr('xlink:href', '#headerPath')
      .attr('class', 'habitsWheel__headerText habitsWheel__hoveredText')
      .attr('id', 'learning')
      .text('Learning Habits of Mind');

    // Outer top ring
    const outerTopRingPath = d3.path();
    outerTopRingPath.arc(circleX, outerCircleRadius, 25.5, 3.14, 0);

    svgEl
      .append('path')
      .style('fill', 'none')
      .attr('d', outerTopRingPath)
      .attr('id', 'outerTopRingPath');

    svgEl
      .append('text')
      .append('textPath')
      .attr('startOffset', '5%')
      .attr('xlink:href', '#outerTopRingPath')
      .attr('class', 'habitsWheel__ringText habitsWheel__nonHoveredText')
      .attr('id', 'ethicalConsideration')
      .on('mouseover', mouseOver)
      .on('mouseout', mouseOut)
      .text('Ethical consideration');

    svgEl
      .append('text')
      .append('textPath')
      .attr('startOffset', '50%')
      .attr('text-anchor', 'middle')
      .attr('xlink:href', '#outerTopRingPath')
      .attr('class', 'habitsWheel__ringText habitsWheel__nonHoveredText')
      .attr('id', 'curiosity')
      .on('mouseover', mouseOver)
      .on('mouseout', mouseOut)
      .text('Curiosity');

    svgEl
      .append('text')
      .append('textPath')
      .attr('startOffset', '70%')
      .attr('xlink:href', '#outerTopRingPath')
      .attr('class', 'habitsWheel__ringText habitsWheel__nonHoveredText')
      .attr('id', 'openMindedness')
      .on('mouseover', mouseOver)
      .on('mouseout', mouseOut)
      .text('Open-mindedness');

    // Bottom outer ring
    const outerBottomRingPath = d3.path();
    outerBottomRingPath.arc(circleX, outerCircleRadius, 26.5, 3.14, 0, true);

    svgEl
      .append('path')
      .style('fill', 'none')
      .attr('d', outerBottomRingPath)
      .attr('id', 'outerBottomRingPath');

    svgEl
      .append('text')
      .append('textPath')
      .attr('startOffset', '7%')
      .attr('xlink:href', '#outerBottomRingPath')
      .attr('class', 'habitsWheel__ringText habitsWheel__nonHoveredText')
      .attr('id', 'reflection')
      .on('mouseover', mouseOver)
      .on('mouseout', mouseOut)
      .text('Reflection');

    svgEl
      .append('text')
      .append('textPath')
      .attr('startOffset', '28%')
      .attr('xlink:href', '#outerBottomRingPath')
      .attr('class', 'habitsWheel__ringText habitsWheel__nonHoveredText')
      .attr('id', 'collaboration')
      .on('mouseover', mouseOver)
      .on('mouseout', mouseOut)
      .text('Collaboration');

    svgEl
      .append('text')
      .append('textPath')
      .attr('startOffset', '55%')
      .attr('xlink:href', '#outerBottomRingPath')
      .attr('class', 'habitsWheel__ringText habitsWheel__nonHoveredText')
      .attr('id', 'resourcefulness')
      .on('mouseover', mouseOver)
      .on('mouseout', mouseOut)
      .text('Resourcefulness');

    svgEl
      .append('text')
      .append('textPath')
      .attr('startOffset', '81%')
      .attr('xlink:href', '#outerBottomRingPath')
      .attr('class', 'habitsWheel__ringText habitsWheel__nonHoveredText')
      .attr('id', 'resilience')
      .on('mouseover', mouseOver)
      .on('mouseout', mouseOut)
      .text('Resilience');

    // Middle ring
    const middleRing = d3.path();
    middleRing.arc(circleX, outerCircleRadius, 21, 3.14, 0);

    svgEl
      .append('path')
      .style('fill', 'none')
      .attr('d', middleRing)
      .attr('id', 'middleRingPath');

    svgEl
      .append('text')
      .append('textPath')
      .attr('startOffset', '50%')
      .attr('text-anchor', 'middle')
      .attr('xlink:href', '#middleRingPath')
      .attr('class', 'habitsWheel__middleRingText habitsWheel__nonInfoText')
      .attr('id', 'engineeringHabitsOfMind')
      .text('Engineering Habits of Mind');

    svgEl
      .append('text')
      .attr('x', '47%')
      .attr('y', '34%')
      .attr('class', 'habitsWheel__ringText habitsWheel__nonHoveredText')
      .attr('id', 'improving')
      .on('mouseout', mouseOut)
      .on('mouseover', mouseOver)
      .text('Improving');

    svgEl
      .append('text')
      .attr('x', '44%')
      .attr('y', '50%')
      .attr('class', 'habitsWheel__ringText habitsWheel__nonHoveredText')
      .attr('id', 'visualizing')
      .on('mouseout', mouseOut)
      .on('mouseover', mouseOver)
      .text('Visualizing');

    const creativeProblemSolvingContainer = svgEl
      .append('g')
      .attr('id', 'creativeProblemSolvingGroup')
      .attr('class', 'habitsWheel__nonHoveredText')
      .on('mouseout', groupedMouseOut)
      .on('mouseover', groupedMouseOver);

    creativeProblemSolvingContainer
      .append('text')
      .attr('x', '50%')
      .attr('y', '69%')
      .attr('class', 'habitsWheel__ringText')
      .attr('id', 'creative')
      .text('Creative');

    creativeProblemSolvingContainer
      .append('text')
      .attr('x', '51%')
      .attr('y', '72%')
      .attr('class', 'habitsWheel__ringText')
      .attr('id', 'creativeProblem')
      .text('Problem');

    creativeProblemSolvingContainer
      .append('text')
      .attr('x', '52.5%')
      .attr('y', '75%')
      .attr('class', 'habitsWheel__ringText')
      .attr('id', 'solving')
      .text('Solving');

    const systemsThinkingContainer = svgEl
      .append('g')
      .attr('id', 'systemsThinkingGroup')
      .attr('class', 'habitsWheel__nonHoveredText')
      .on('mouseout', groupedMouseOut)
      .on('mouseover', groupedMouseOver);

    systemsThinkingContainer
      .append('text')
      .attr('x', '79%')
      .attr('y', '34%')
      .attr('class', 'habitsWheel__ringText')
      .attr('id', 'systems')
      .text('Systems');

    systemsThinkingContainer
      .append('text')
      .attr('x', '80%')
      .attr('y', '37%')
      .attr('class', 'habitsWheel__ringText')
      .attr('id', 'thinking')
      .text('thinking');

    svgEl
      .append('text')
      .attr('x', '82.5%')
      .attr('y', '50%')
      .attr('class', 'habitsWheel__ringText habitsWheel__nonHoveredText')
      .attr('id', 'adapting')
      .on('mouseout', mouseOut)
      .on('mouseover', mouseOver)
      .text('Adapting');

    const problemFindingContainer = svgEl
      .append('g')
      .attr('id', 'problemFindingGroup')
      .attr('class', 'habitsWheel__nonHoveredText')
      .on('mouseout', groupedMouseOut)
      .on('mouseover', groupedMouseOver);

    problemFindingContainer
      .append('text')
      .attr('x', '78%')
      .attr('y', '69%')
      .attr('class', 'habitsWheel__ringText')
      .attr('id', 'problem')
      .text('Problem');

    problemFindingContainer
      .append('text')
      .attr('x', '77%')
      .attr('y', '72%')
      .attr('class', 'habitsWheel__ringText')
      .attr('id', 'finding')
      .text('Finding');

    // Center Header text
    const centerTopRing = d3.path();
    centerTopRing.arc(circleX, outerCircleRadius, 12, 3.14, 0);

    svgEl
      .append('path')
      .style('fill', 'none')
      .attr('d', centerTopRing)
      .attr('id', 'centerTopRingPath');

    svgEl
      .append('text')
      .append('textPath')
      .attr('startOffset', '50%')
      .attr('text-anchor', 'middle')
      .attr('xlink:href', '#centerTopRingPath')
      .attr('class', 'habitsWheel__centerRingText habitsWheel__nonInfoText')
      .attr('id', 'core')
      .text('Core');

    const centerMiddleRing = d3.path();
    centerMiddleRing.arc(circleX, outerCircleRadius, 10.5, 3.14, 0);

    svgEl
      .append('path')
      .style('fill', 'none')
      .attr('d', centerMiddleRing)
      .attr('id', 'centerMiddleRingPath');

    svgEl
      .append('text')
      .append('textPath')
      .attr('startOffset', '50%')
      .attr('text-anchor', 'middle')
      .attr('xlink:href', '#centerMiddleRingPath')
      .attr('class', 'habitsWheel__centerRingText habitsWheel__nonInfoText')
      .attr('id', 'engineering')
      .text('Engineering');

    const centerBottomRing = d3.path();
    centerBottomRing.arc(circleX, outerCircleRadius, 8.75, 3.14, 0);

    svgEl
      .append('path')
      .style('fill', 'none')
      .attr('d', centerBottomRing)
      .attr('id', 'centerBottomRingPath');

    svgEl
      .append('text')
      .append('textPath')
      .attr('startOffset', '50%')
      .attr('text-anchor', 'middle')
      .attr('xlink:href', '#centerBottomRingPath')
      .attr('class', 'habitsWheel__centerRingText habitsWheel__nonInfoText')
      .attr('id', 'mind')
      .text('Mind');

    // Center phrase
    svgEl
      .append('text')
      .attr('x', '67%')
      .attr('y', '45.5%')
      .attr('text-anchor', 'middle')
      .attr('class', 'habitsWheel__centerPhraseText')
      .text("Making 'things'")
      .on('click', navigateToEducators);

    svgEl
      .append('text')
      .attr('x', '67%')
      .attr('y', '48.5%')
      .attr('text-anchor', 'middle')
      .attr('class', 'habitsWheel__centerPhraseText')
      .text('that work and')
      .on('click', navigateToEducators);

    svgEl
      .append('text')
      .attr('x', '67%')
      .attr('y', '51.5%')
      .attr('text-anchor', 'middle')
      .attr('class', 'habitsWheel__centerPhraseText')
      .text("making 'things'")
      .on('click', navigateToEducators);
    svgEl
      .append('text')
      .attr('x', '67%')
      .attr('y', '54.5%')
      .attr('text-anchor', 'middle')
      .attr('class', 'habitsWheel__centerPhraseText')
      .text('work better')
      .on('click', navigateToEducators);
  }, []);

  return (
    <div className="habitsWheel__container">
      <svg
        ref={svgRef}
        viewBox="0,0,100,66"
        preserveAspectRatio="xMinYMin"
      ></svg>
      <div className="habitsWheel__infoContainer">
        <h3 className="habitsWheel__infoHeaderText">{textInfo.header}</h3>
        <div className="habitsWheel__infoDescriptionText">
          {textInfo.description}
        </div>
      </div>
      <div className="habitsWheel__sourceContainer">
        Source: The Status and Nature of K-12 Engineering Education in the
        United States, L. Katehi, G. Pearson and M. Feder, The Bridge, National
        Academy of Engineering Vol. 39 Issue 3, September 2009
      </div>
    </div>
  );
};

export default HabitsWheel;
