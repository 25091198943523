import React from 'react';
import './styles.scss';
import HabitsAccordionTab from './HabitsAccordionTab';

const HabitsAccordion = () => {
  const accordionTabs = [
    {
      tabText: 'Learning Habits of Mind',
      infoText:
        'Students can be as methodical and organized in their approach to learning as engineers are in solving problems! These Habits of Mind are designed to equip students with a functional framework for navigating new ideas and expanding their knowledge into new territories.',
      infoClasses: ['habitsAccordion__learningHabitsSection'],
      tabClasses: [
        'habitsAccordion__headerText',
        'habitsAccordion__learningHabitsSection',
      ],
    },
    {
      tabText: 'Ethical Consideration',
      infoText:
        'Engineering solutions must be considered within the context of people and the environment to help avoid unintended consequences and potential inequities.',
      infoClasses: ['habitsAccordion__learningHabitsSection'],
      tabClasses: ['habitsAccordion__learningHabitsSection'],
    },
    {
      tabText: 'Curiosity',
      infoText:
        'A healthy appetite for asking questions and exploring the unknown is the basis of all knowledge-building.',
      infoClasses: ['habitsAccordion__learningHabitsSection'],
      tabClasses: ['habitsAccordion__learningHabitsSection'],
    },
    {
      tabText: 'Open-mindedness',
      infoText:
        'New discoveries require thinking that is flexible and adaptable to the shifting challenges of the moment.',
      infoClasses: ['habitsAccordion__learningHabitsSection'],
      tabClasses: ['habitsAccordion__learningHabitsSection'],
    },
    {
      tabText: 'Resilience',
      infoText:
        'Great ideas don’t always come immediately, but patience and persistence always pay off in the problem-solving process.',
      infoClasses: ['habitsAccordion__learningHabitsSection'],
      tabClasses: ['habitsAccordion__learningHabitsSection'],
    },
    {
      tabText: 'Resourcefulness',
      infoText:
        'Learners must actively engage in developing new ideas and have an awareness of any and all opportunities available to them.',
      infoClasses: ['habitsAccordion__learningHabitsSection'],
      tabClasses: ['habitsAccordion__learningHabitsSection'],
    },
    {
      tabText: 'Collaboration',
      infoText:
        'Engineering is a team sport which depends on the unique skills of every team member to achieve a whole that is greater than the sum of its parts.',
      infoClasses: ['habitsAccordion__learningHabitsSection'],
      tabClasses: ['habitsAccordion__learningHabitsSection'],
    },
    {
      tabText: 'Reflection',
      infoText:
        'Analyzing what has already taken place is sometimes the most fruitful way of predicting or deciding what’s coming next.',
      infoClasses: ['habitsAccordion__learningHabitsSection'],
      tabClasses: ['habitsAccordion__learningHabitsSection'],
    },
    {
      tabText: 'Engineering Habits of Mind',
      infoClasses: ['habitsAccordion__engineeringHabitsSection'],
      tabClasses: [
        'habitsAccordion__headerText',
        'habitsAccordion__engineeringHabitsSection',
      ],
    },
    {
      tabText: 'Systems Thinking',
      infoText:
        'Systems give students a “bigger picture” understanding of the technological world and its complex interconnections, which can sometimes result in unpredictable effects.',
      infoClasses: ['habitsAccordion__engineeringHabitsSection'],
      tabClasses: ['habitsAccordion__engineeringHabitsSection'],
    },
    {
      tabText: 'Adapting',
      infoText:
        'Engineering is in a constant state of evolution to help tackle new problems and utilize new technology, which is why flexible thinking is an essential skill.',
      infoClasses: ['habitsAccordion__engineeringHabitsSection'],
      tabClasses: ['habitsAccordion__engineeringHabitsSection'],
    },
    {
      tabText: 'Problem Finding',
      infoText:
        'Problem-solving is driven by the curiosity to identify what is wrong in the world and the passion to want help fix it.',
      infoClasses: ['habitsAccordion__engineeringHabitsSection'],
      tabClasses: ['habitsAccordion__engineeringHabitsSection'],
    },
    {
      tabText: 'Creating Problem Solving',
      infoText:
        'Engineers translate ideas from their minds to the real world by combining the scientific process with creative thinking.',
      infoClasses: ['habitsAccordion__engineeringHabitsSection'],
      tabClasses: ['habitsAccordion__engineeringHabitsSection'],
    },
    {
      tabText: 'Visualizing',
      infoText:
        'Humans are visual thinkers, and the mind’s eye is the primary workshop of the Engineer.',
      infoClasses: ['habitsAccordion__engineeringHabitsSection'],
      tabClasses: ['habitsAccordion__engineeringHabitsSection'],
    },
    {
      tabText: 'Improving',
      infoText:
        'Technology is based on the concept of building a brighter future, and every engineering challenge represents a world of possibilities and opportunities to grow',
      infoClasses: ['habitsAccordion__engineeringHabitsSection'],
      tabClasses: ['habitsAccordion__engineeringHabitsSection'],
    },
    {
      tabText: 'Core Engineering Mind',
      infoClasses: ['habitsAccordion__coreEngineeringSection'],
      tabClasses: [
        'habitsAccordion__headerText',
        'habitsAccordion__coreEngineeringSection',
      ],
    },
    {
      tabText: "Making 'things' that work and making 'things' work better",
      infoText:
        'Engineering is anchored in tangible solutions to real-world problems.',
      infoClasses: ['habitsAccordion__coreEngineeringSection'],
      tabClasses: ['habitsAccordion__coreEngineeringSection'],
    },
  ];

  return (
    <div className="habitsAccordion__container">
      {accordionTabs.map((tab, i) => (
        <HabitsAccordionTab
          infoClasses={tab.infoClasses}
          infoText={tab.infoText}
          key={i}
          tabClasses={tab.tabClasses}
          tabText={tab.tabText}
        />
      ))}
      <div className="habitsAccordion__source">
        Source: The Status and Nature of K-12 Engineering Education in the
        United States, L. Katehi, G. Pearson and M. Feder, The Bridge, National
        Academy of Engineering Vol. 39 Issue 3, September 2009
      </div>
    </div>
  );
};

export default HabitsAccordion;
